<template>
  <v-container>
    <v-card max-width="800" class="mx-auto mt-10">
      <v-card-title>
        {{$t('email')}}
      </v-card-title>
      <v-card-text>
        {{$t('loginflow.give_your_email')}}
        <v-form @submit.prevent>
          <v-text-field
            class="mt-6"
            dense
            solo
            v-model="email"
            :label="$t('email')"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <!-- <VueRecaptcha
        ref="recaptcha"
        sitekey="6Lcn8EcgAAAAAJAC7I-E3XQy9j0v2xt-tvs0VbD6"
        @onload="loaded"
        @verify="verifyMethod"
        @expired="errorMethod"
        @render="renderMethod"
        @error="errorMethod"
      /> -->
      <v-card-actions>
        <v-btn
          @click="$router.push('/login')"
        >
          {{$t('back')}}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="startOrder"
          :loading="loading"
        >
          {{$t('loginflow.send')}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
// import { VueRecaptcha } from 'vue-recaptcha';

export default {
  data: () => ({
    email: '',
    loading: false
  }),
  // components: { VueRecaptcha },
  methods: {
    verifyMethod(e) {
      console.log(e)
    },
    errorMethod(e) {
      alert(JSON.stringify(e))
    },
    renderMethod(e) {
      console.log('render', e)
    },
    loaded() {
      alert('loadded')
    },
    startOrder() {
      this.loading = true
      this.orderLink(this.email)
        .then(() => this.success(this.$t('loginflow.link_sent')))
        .catch(e => this.error(e))
        .finally(() => {
          this.loading = false
        })
    },
    ...mapActions('auth', [
      'orderLink'
    ]),
    ...mapActions('noti', [
      'error',
      'success'
    ])
  }
}
</script>