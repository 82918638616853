<template>
  <div></div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  created() {
    // let session_id = this.$route.query.session_id
    // if(session_id) {
    //   this.stripeSessionById(session_id)
    //     .then(e => {
    //       alert(JSON.stringify(e))
    //     })
    //     .catch(e => this.error(e))
    // }
    
    this.refetchToken(window.localStorage.getItem('tiimio-latest-team'))
      .then(e => {
        window.localStorage.setItem('tiimio-token', e.data.token)
        this.initUser(e.data.token)

        this.$nextTick(() => {
          this.$router.push({path: '/videos', query: { ...this.$route.query }})
        })
      })
      .catch(e => {
        this.error(e)

        this.$router.push('/videos')
      })
  },
  methods: {
    ...mapActions('auth', [
      'refetchToken',
      'stripeSessionById'
    ]),
    ...mapActions('user', [
      'initUser'
    ]),
    ...mapActions('noti', [
      'error',
      'success'
    ]),
  }
}
</script>